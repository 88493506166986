import Cookies from 'js-cookie';


export function updateTokens(dataJSON) {
  Cookies.set("token", dataJSON.token);
  Cookies.set("refreshToken", dataJSON.refreshToken);
}

export function clearTokens() {
  Cookies.set("token", "");
  Cookies.set("refreshToken", "");  
}

export function getCookie(cookie) {
  return Cookies.get(cookie)
}