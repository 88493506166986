import { attemptRefresh } from './accountService';
import config from '../config';
import { getCookie } from "./cookieManager";

export async function getValidPayments(setValidPayments) {
    const response = await fetch(`${config.apiBaseUrl}/getValidPayments`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        }});
    if (response.ok === true) {
        response.json().then(data => setValidPayments(data.data))
        return response.ok;
    }
    else { 
        var validity = await attemptRefresh()
        return validity
    }
}

export async function getAllPayments(setAllPayments) {
    const response = await fetch(`${config.apiBaseUrl}/getPayments`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        }});
    if (response.ok === true) {
        response.json().then(data => setAllPayments(data.data))
        return response.ok;
    }
    else { 
        var validity = await attemptRefresh()
        return validity
    }
}