import React from 'react'
import SignIn from '../components/SignIn'
import Footer from '../components/Footer'

const SignInPage = () => {
  return (
    <div>
      <SignIn/>
      <Footer minFooterWidth="300px"/>
    </div>
  )
}

export default SignInPage