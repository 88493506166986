import React from 'react'
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
} from './TOSElements'
import template from './TOS.html';

const TOS = () => {
  const height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const htmlContent = { __html: template };
  return (
    <Container style={{"min-height":height}}>
      <Icon to="/">Dereva</Icon>
      <FormWrap style={{"min-height":height -160}}>
        <FormContent>
          <Form>
          <div id="htmlContentContainer" dangerouslySetInnerHTML={htmlContent} />
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
  )
}

export default TOS;

