import React from 'react'
import Docs from '../components/Docs'
import Footer from '../components/Footer'

const DocsPage = () => {
  return (
    <div>
      <Docs />
      <Footer />
    </div>
  )
}

export default DocsPage