import React, { useState } from 'react';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  TextLink,
  StyledCheckbox,
  fontTheme
} from './SignUpElements';
import { registerAccount } from '../../services/accountService';
import { Link, FormControlLabel, Typography } from "@mui/material";

const SignUp = () => {
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');


  const handleEmailChange = (event) => {
    var email_input = document.getElementById('regEmail');
    email_input.setCustomValidity('');
    setEmail(event.target.value);
  };

  const handlePassChange = (event) => {
    setPass(event.target.value)
  }

  const handleConfirmPassChange = (event) => {
    var conf_input = document.getElementById("confpass");
    (event.target.value === password ? 
      conf_input.setCustomValidity("") : conf_input.setCustomValidity("Passwords must match."))
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleCompanyChange = (event) => {
    setCompany(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();


    var email_input = document.getElementById('regEmail');

    registerAccount(email, password, name, company).then((response) => {
      if (response === true) {
        window.parent.location = `/account`;
      } else {
        console.log('hi');
        email_input.setCustomValidity('Email is taken.');
      }
    });
  };

  return (
    <>
      <Container style={{ minHeight: height }}>
        <Icon to="/">Dereva</Icon>
        <FormWrap style={{ minHeight: height - 160 }}>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Sign up with your email and a secure password!</FormH1>
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput id="regEmail" type="email" onChange={handleEmailChange} required />
              <FormLabel htmlFor='for'>Password</FormLabel> 
              <FormInput id='regPassword' type='password' onInput={handlePassChange} required />
              <FormLabel htmlFor='for'>Confirm password</FormLabel> 
              <FormInput id='confpass' type='password' onInput={handleConfirmPassChange} required />
              <FormLabel htmlFor='for'>Name (optional)</FormLabel> 
              <FormInput id='name' type='text' onInput={handleNameChange} /> 
              <FormLabel htmlFor='for'>Company (optional)</FormLabel> 
              <FormInput id='company' type='text' onInput={handleCompanyChange} /> 
              <FormControlLabel
                control={
                  <StyledCheckbox
                    required
                    style={{ color: '#fff' }}
                  />
                }
                label={
                  <Typography theme={fontTheme} sx={{ color: '#ffffff' }}>
                    I agree to the{' '}
                    <Link href="/privacypolicy" sx={{    
                      color: '#2563eb',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      }}}>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link href="/termsofservice" sx={{    
                      color: '#2563eb',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      }}}>
                      Terms and Conditions
                    </Link>.
                  </Typography>
                }
              />
              <FormButton>Create Account</FormButton>
              <TextLink to="/signin">Already have an account? Sign in here.</TextLink>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default SignUp;
