import React from 'react';
import {
  Container,
  FormWrap,
  NavWrap,
  Icon,
  FormContent,
  Form,
  Divider,
  InvestorCard,
  InvestorImage,
  InvestorName,
  InvestorDescription,
  CustomLi
} from './InvestElements';
import { Box, Typography, Link } from '@mui/material';
import jamesHeadshot from '../../images/james-headshot.jpg'

const Invest = () => {
  return (
    <>
      <Container style={{ minHeight: "100vh" }}>
        <NavWrap>
          <Icon to="/">Dereva</Icon>
        </NavWrap>
        <FormWrap>
          <FormContent>
            <Form>
              <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: "flex", flexDirection: "column", padding: "2rem" }}>
                {/* Current Investors Section */}
                <div style={{ marginBottom: "2rem", marginTop: "5rem" }}>
                  <Typography variant="h5" align="center">Current Investors</Typography>
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                    <InvestorCard>
                      <InvestorImage src={jamesHeadshot} alt="Investor 1" />
                      <InvestorName>James Rogers</InvestorName>
                      <InvestorDescription>Founder</InvestorDescription>
                    </InvestorCard>
                  </div>
                </div>
                <Divider style={{ margin: "0 auto", width: "70%", marginBottom: "2rem", marginTop: "5rem" }} />
                                {/* Who We Are Looking For */}
                                <div style={{ marginBottom: "2rem" }}>
                  <Typography variant="h5" align="center" style={{ marginTop: "3rem", marginBottom: "3rem" }}>Who We Are Looking For to Invest</Typography>
                  <Typography variant="h6" style={{ marginTop: "1rem" }}>
                    At Dereva Systems, we are actively seeking experienced individuals to invest in our company. Here's why we are looking for investors:
                  </Typography>
                  <ul style={{ marginTop: "1rem", fontSize: "1.2rem" }}>
                    <CustomLi>Rapid Expansion: By partnering with experienced investors, we can secure the necessary resources to hire more talented professionals and rapidly expand our team. This allows us to take on more projects and meet the growing demand for our automation consulting services.</CustomLi>
                    <CustomLi>Expertise and Network: Investors with industry expertise and a strong network bring valuable knowledge, connections, and mentorship to our company. Their guidance and insights help us navigate challenges, explore new opportunities, and stay ahead in the competitive automation consulting market.</CustomLi>
                    <CustomLi>Strategic Support: Investments from experienced individuals provide strategic support in terms of financial resources, business development, and market expansion. With their support, we can execute our growth plans, forge strategic partnerships, and capitalize on emerging trends in the automation industry.</CustomLi>
                  </ul>
                </div>
                <Divider style={{ margin: "0 auto", width: "70%", marginBottom: "2rem" }} />
                {/* Why Invest Section */}
                <div>
                  <Typography variant="h5" align="center" style={{ marginTop: "3rem", marginBottom: "3rem" }}>Why Invest in Our Company?</Typography>
                  <Typography variant="h6" style={{ marginTop: "1rem" }}>
                    At Dereva Systems, we are a leading automation consulting firm in the ever-evolving industry. Here are some compelling reasons to consider investing in our company:
                  </Typography>
                  <ol style={{ marginTop: "1rem", fontSize: "1.2rem", marginBottom: "2rem" }}>
                    <CustomLi>Expansive Market Potential: The automation consulting industry has experienced significant growth in recent years, and there is still ample room for further expansion. As businesses increasingly embrace automation solutions, our expertise positions us at the forefront of this growing market.</CustomLi>
                    <CustomLi>Pioneering Automation Solutions: We develop cutting-edge automation solutions that drive efficiency, productivity, and cost savings for our clients. Our innovative automation algorithms and strategies set us apart from the competition.</CustomLi>
                    <CustomLi>Proven Track Record: With a solid track record of successful projects and satisfied clients, we have built a reputation for delivering tangible results. Our expertise, experience, and client testimonials speak to our ability to consistently deliver high-quality automation solutions.</CustomLi>
                    <CustomLi>Versatile Business Model: Our business model is highly versatile, allowing us to adapt to various client needs. We can learn company procedures to create hand-tailored automation solutions, bring pre-existing ideas to life, provide general IT support, and collaborate with other automation firms for complex projects.</CustomLi>
                    <CustomLi>Diversified Service Portfolio: We offer a comprehensive range of automation services, including process automation, robotic process automation (RPA), intelligent automation, and automation strategy consulting. This diversified portfolio enables us to cater to various industries and client needs.</CustomLi>
                    <CustomLi>Scalability and Growth Potential: Our business model is designed for scalability, allowing us to expand rapidly as demand increases. We have identified strategic opportunities for growth and have a clear roadmap to capitalize on them.</CustomLi>
                    <CustomLi>Strong Market Position: Through strategic partnerships, industry collaborations, and ongoing research and development, we maintain a strong market position. Our commitment to staying at the forefront of automation technology ensures that we remain relevant in an ever-changing landscape.</CustomLi>
                  </ol>
                </div>
                <Divider style={{ margin: "0 auto", width: "70%", marginBottom: "2rem" }} />
                {/* Where To Inevst Section */}
                <Typography variant="h6" align="center" style={{ marginTop: "1rem" }}>
                  If you are interested in becoming an investor, you can contact us {" "}
                  <Link href="/contact" sx={{    
                  color: '#2563eb',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  }}}>
                    here
                  </Link>
                  .
                </Typography>
              </Box>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Invest;
