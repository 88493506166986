import React from 'react'
import Aboutus from '../components/Aboutus'
import Footer from '../components/Footer'

const AboutusPage = () => {
  return (
    <div>
      <Aboutus />
      <Footer />
    </div>
  )
}

export default AboutusPage