import React, { useState } from 'react'
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  FormTextarea
} from './ContactElements'
import { sendContactEmail } from '../../services/emailService';


const Contact = () => {
  var height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const [email, setEmail] = useState()
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState()


  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSubjectChange = (event) => {
    setSubject(event.target.value)
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  const handleSubmit = () => {
    sendContactEmail(email, subject, message);
    window.parent.location('/');
  }

  return (
    <>
    <Container style={{}}>
      <Icon to="/">Dereva</Icon>
      <FormWrap style={{"minHeight":height}}>
        <FormContent>
          <Form action="#">
            <FormH1>Contact us directly using the form below. Great for any questions or feedback!</FormH1>
            <FormLabel htmlFor='for'>Email</FormLabel>
            <FormInput id='email' type='email' onChange={handleEmailChange} required />
            <FormLabel htmlFor='for'>Subject</FormLabel>
            <FormInput id='subject' onChange={handleSubjectChange} />
            <FormLabel htmlFor="message">Message</FormLabel>
            <FormTextarea id="message" onChange={handleMessageChange} required />
            <FormButton onClick={handleSubmit} to='/'>Submit</FormButton>
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
    </>
  )
}

export default Contact
