import React, { useEffect, useRef } from 'react'
import { Typography, Box, Link, List, ListItem, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material'
import {
  FormBtn,
  fontTheme
} from '../AccountElements'
import { postTemplate, deleteTemplate, getSimpleTemplates } from '../../../services/emailService';

const EmailPanel = ({ value, TabPanel, logout, data }) => {
  const [templates, setTemplates] = React.useState([
    { id: 1, name: 'Template 1' },
    { id: 2, name: 'Template 2' },
    { id: 3, name: 'Template 3' },
  ]);
  
  const sortedTemplates = templates.slice().sort((a, b) => a.id - b.id);

  const fileInputRef = useRef(null);
  var remainingTokens = 0;
  var remainingTemplates = 0;

  if (data !== undefined && data['Email'] !== undefined){
    remainingTokens = data['Email'].payment.remainingtokens;
    remainingTemplates = data['Email'].service.attachments - templates.length;
  }


  const handleDeleteTemplate = (id) => {
    setTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== id));
    deleteTemplate(id);
  };

  const handleEditTemplate = (id, event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file != null) {
      postTemplate(id, file)
        .then(validity => {
          if (validity !== false) {
            updateSimpleTemplates();
            console.log(validity);
          }
        })
        .catch(error => {
          console.error("Error posting template:", error);
        });
    }
  };

  const handleEditClick = (templateId) => {
    console.log("Edit button clicked for template ID:", templateId);
    const templateInput = document.querySelector(`[data-template-id="${templateId}"]`);
    if (templateInput) {
      templateInput.click();
    }
  };


  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  }

  const handleFileUpload = () => {
    const file = fileInputRef.current.files[0];
    postTemplate(null, file).then(validity => {
      if (validity !== false) {
        updateSimpleTemplates()
      }
      console.log(validity);
    })
  }

  const updateSimpleTemplates = () => {
    getSimpleTemplates(setTemplates).then(validity => {
      if (validity === false) {
        logout()
      }
  })}

  useEffect(() => {
    updateSimpleTemplates()
  }, [])

    return (
      <TabPanel value={value} index={3} >
        <Box sx={{    
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ededed',
          borderRadius: 8,
          padding: '16px 24px',
          marginBottom: 3}} 
        >

          {remainingTokens > 0 ? (
            <Box sx={{    
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'}} 
            >
            <Typography theme={fontTheme} sx={{    
              fontSize: 25,
              fontWeight: 'bold',
              marginBottom: 2}}
            >
              Remaining Tokens:
            </Typography>
            <Typography theme={fontTheme} sx={{    
              fontSize: 60,
              fontWeight: 'bold',
              color: '#444444',
              marginBottom: 2,}}
            >
              {remainingTokens}
            </Typography>
            </Box>
          ) : (
            <Typography>
              You have no remaining tokens. To acquire more, please{' '}
              <Link href="/plans" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                upgrade your plan
              </Link>
              .
            </Typography>
          )}
        </Box>
        <Box sx={{    
          backgroundColor: '#ededed',
          borderRadius: 8,
          padding: '16px 24px',
          marginBottom: 4,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'}}>
            <FormBtn onClick={handleButtonClick} disabled={remainingTemplates <= 0}>
              Upload an email template
            </FormBtn>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />

            <List sx={{
                maxHeight: 250,
                width: '90%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <ListItem sx={{ borderBottom: '1px solid #bdbdbd', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography theme={fontTheme} sx={{ fontWeight: 'bold', width: '15%', textAlign: 'center' }}>
                        ID
                    </Typography>
                    <Typography theme={fontTheme} sx={{ fontWeight: 'bold', width: '65%', textAlign: 'center' }}>
                        Name
                    </Typography>
                    <Typography theme={fontTheme} sx={{ paddingRight: '10px', fontWeight: 'bold', width: '10%', textAlign: 'center' }}>
                        Edit
                    </Typography>
                    <Typography theme={fontTheme} sx={{ fontWeight: 'bold', width: '10%', textAlign: 'center' }}>
                        Remove
                    </Typography>
                </ListItem>
                {sortedTemplates.map((template) => (
                    <ListItem key={template.id} sx={{ borderBottom: '1px solid #bdbdbd', display: 'flex' }}>
                        <Typography theme={fontTheme} sx={{ width: '15%' }}>
                          {template.id}
                        </Typography>
                        <Typography theme={fontTheme} sx={{ width: '65%' }}>
                          {template.name}
                        </Typography>
                        <ListItemSecondaryAction style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="edit" style={{ width: '50%' }} onClick={() => handleEditClick(template.id)}>
                            <Edit />
                            </IconButton>
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => handleEditTemplate(template.id, event)}
                            data-template-id={template.id}
                          />
                          <IconButton aria-label="delete" style={{width:'50%'}} onClick={() => handleDeleteTemplate(template.id)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
          <Typography variant="subtitle1" >
              {remainingTemplates} templates remaining
            </Typography>
        </Box>
        <Box sx={{    
          backgroundColor: '#ededed',
          borderRadius: 8,
          padding: '16px 24px',
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'}}>
            <Typography>
              To find out more about the API,{' '}
              <Link href="/docs" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                see our docs
              </Link>
              . To manage your plan,{' '}
              <Link href="/plans" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                click here
              </Link>
              .
            </Typography>
          </Box>
        </TabPanel>
)
}

export default EmailPanel