import React, { useEffect } from 'react'
import {
    FormBtn, ResetEmailText, CancelBtn
  } from '../AccountElements'
  import { 
    Box, 
    Typography,
    TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link
  } from '@mui/material';
import { getAccountDetails, updateAccountDetails } from '../../../services/accountService';
import { sendResetPassEmail, sendDeleteAccountEmail } from '../../../services/emailService';

const HomePanel = ({ value, TabPanel, logout, payments }) => {
    const [account, setAccount] = React.useState(JSON.stringify({"email":"","password":"","name":"","company":""}));
    const [isMounted, setIsMounted] = React.useState(false);
    const [resetEmailSent, setResetEmailSent] = React.useState(false);
    const [deleteEmailSent, setDeleteEmailSent] = React.useState(false);

    const handleResetPassword = (e) => {
      e.preventDefault()
      sendResetPassEmail(account.email)
      setResetEmailSent(true)
    }

    const handleDeleteAccount = (e) => {
      e.preventDefault()
      sendDeleteAccountEmail(account.email)
      setDeleteEmailSent(true)
    }
  
    const handleNameChange = (event) => {
      setAccount((prevAccount) => ({
        ...prevAccount,
        name: event.target.value,
      }));
    };
    
    const handleCompanyChange = (event) => {
      setAccount((prevAccount) => ({
        ...prevAccount,
        company: event.target.value,
      }));
    };

    const styles = {
      tableContainer: {
        maxHeight: 400,
        overflowY: 'auto',
      },
    };

    function DataTable({ data }) {
      const valuesList = Object.values(data);
      return (
        <TableContainer style={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "25%" }}><b>Date Activated</b></TableCell>
                <TableCell style={{ width: "25%" }}><b>Service</b></TableCell>
                <TableCell style={{ width: "25%" }}><b>Plan</b></TableCell>
                <TableCell style={{ width: "25%" }}><b>Remaining Tokens</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {valuesList.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: "25%" }}>{new Date(item.payment.date).toLocaleDateString()}</TableCell>
                  <TableCell style={{ width: "25%" }}>{item.service.service}</TableCell>
                  <TableCell style={{ width: "25%" }}>{item.service.plan}</TableCell>
                  <TableCell style={{ width: "25%" }}>{item.payment.remainingtokens}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    useEffect(() => {
      if (isMounted) {
        updateAccountDetails(account, setAccount);
      } else {
        setIsMounted(true);
      }
    }, [account]);

    useEffect(() => {
        getAccountDetails(setAccount).then(validity => {
        if (validity === false) {
          logout()
        }})
      }, [])


    return (
        <TabPanel value={value} index={0} >
          <Box sx={{ flexWrap:'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingBottom: 5, paddingTop: 5, paddingLeft: 3 }}>
            <Typography variant="h4" component="h2">
                Welcome, {account.name}!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingBottom: 5 }}>
            <Typography variant="subtitle1" component="h2" >
                Your basic information...
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', borderRadius: 1 }}>
            <TextField disabled id="email" label="Email" value={account.email} InputLabelProps={{ shrink: true }}/>
            <TextField id="name" label="Name" value={account.name} onChange={handleNameChange} InputLabelProps={{ shrink: true }}/>
            <TextField id="company" label="Company" value={account.company} onChange={handleCompanyChange} InputLabelProps={{ shrink: true }}    />
          </Box>
          <Box sx={{ display: 'flex', 'flex-direction':'row', justifyContent: 'space-evenly', alignItems: 'center', borderRadius: 1, paddingTop: 10, paddingBottom: 10 }}>
          <Box sx={{ display: 'flex', 'flex-direction':'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <FormBtn onClick={handleResetPassword}>
              Reset Password
            </FormBtn>
            {resetEmailSent && (
              <ResetEmailText>A reset email has been sent to your inbox.</ResetEmailText>
            )}
          </Box>
          <Box sx={{ display: 'flex', 'flex-direction':'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <CancelBtn onClick={handleDeleteAccount}>
              Delete Account
            </CancelBtn>
            {deleteEmailSent && (
              <ResetEmailText>A confirmation email has been sent to your inbox.</ResetEmailText>
            )}
          </Box>
          </Box>
          <Box sx={{    
            backgroundColor: '#ededed',
            borderRadius: 8,
            padding: '16px 24px',
            marginBottom: 4,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'}}>
            <Typography variant="subtitle1" component="h2" sx={{"font-weight": "bold"}}>
                Your active subscriptions
            </Typography>
            
            {Object.keys(payments).length ? (
            <DataTable data={payments}/>
            ):(            
            <Typography>
              You have no active plans. Click here to{' '}
            <Link href="/plans" sx={{    
            color: '#2563eb',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            }}}>
              view plans
            </Link>
            .
          </Typography>)}
          </Box>
        </TabPanel>
)
}

export default HomePanel