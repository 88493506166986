import React from 'react'
import PropTypes from 'prop-types';
import {
  Container,
  FormWrap,
  NavWrap,
  Icon,
  FormContent,
  Form,
  Divider
} from './DocsElements'
import { 
  Box, 
  Tab, 
  Tabs, 
  Typography
} from '@mui/material';
import OverviewPanel from './panels/OverviewPanel';
import EmailPanel from './panels/EmailPanel';
import FaxPanel from './panels/FaxPanel';
import SmsPanel from './panels/SmsPanel';
import ProjectsPanel from './panels/ProjectsPanel';
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Docs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 960, // Medium devices (tablets, 768px and up)
      },
    },
  });
  const isBelow960 = useMediaQuery(theme.breakpoints.down("md"));
  



  return (
    <>
      <Container>
        <NavWrap>
          <Icon to="/">Dereva</Icon>
        </NavWrap>
        <FormWrap>
          <FormContent>
            <Form>
              <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: "flex", flexDirection: isBelow960 ? "column" : "row"}}>
                <Tabs value={value} onChange={handleChange} orientation={isBelow960 ? "horizontal" : "vertical"} sx={{ borderRight: isBelow960 ? 0 : 1, borderBottom: isBelow960 ? 1 : 0,  borderColor: 'divider' }}>
                  <Tab label="Overview" {...allyProps(0)} />
                  <Divider  />
                  <Tab label="Fax" {...allyProps(1)} />
                  <Tab label="Email" {...allyProps(2)} />
                  <Tab label="SMS" {...allyProps(3)} />
                  <Divider />
                  <Tab label="Projects" {...allyProps(4)} />
                </Tabs>
                <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
                  <OverviewPanel value={value} TabPanel={TabPanel}/>
                  <FaxPanel value={value} TabPanel={TabPanel}/>
                  <EmailPanel value={value} TabPanel={TabPanel}/>
                  <SmsPanel value={value} TabPanel={TabPanel}/>
                  <ProjectsPanel value={value} TabPanel={TabPanel}/>
                </Box>
              </Box>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default Docs

