import React from 'react'
import {
    fontTheme, linkStyles, H1Styles, P1Styles
  } from '../DocsElements'
  import { 
    Box,
    Typography,
    Link
  } from '@mui/material';

const ProjectsPanel = ({ value, TabPanel}) => {
  return (
    <TabPanel value={value} index={6} >
      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingLeft: 3}}>
        <div >
          <Typography theme={fontTheme} style={H1Styles}>
            Projects
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            At Dereva Systems, we specialize in providing cutting-edge automation solutions for businesses of all sizes. With our expertise in technology and industry knowledge, we help streamline processes, increase efficiency, and reduce costs. We work closely with you to understand your business goals and challenges, and then design and implement automation systems that optimize your operations.
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            Automation is more than a job for us, it is an imperative. As such, we strive to meet your business needs no matter how unique. Implementation of digital solutions such as large language models (LLMs) can save a business thousands hours alone. To find out how we can best save your company time and maximize efficiency, visit our {' '}
            <Link href="/howitworks" style={linkStyles}>
              how it works
            </Link>
            {' '} page and book a  {' '}
            <Link href="/booknow" style={linkStyles}>
              free initial consultation
            </Link>
            .
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            We also offer more generalized solutions through our API library, giving users options to implement pre-built tools into existing systems. As Dereva grows and develops more new and exciting technologies, this library will grow with it. With a robust set of documentation and support resources, we ensure a smooth integration process and provide robust assistance to maximize the value you derive from our services. To gain access to all of our services, you need to 
            {' '}
            <Link href="/signup" style={linkStyles}>
              sign up 
            </Link>
            {' '} for an account and subscribe to a service plan from the {' '}
            <Link href="/plans" style={linkStyles}>
              plans page
            </Link>
            .
          </Typography>
        </div>
      </Box>
    </TabPanel>
)
}

export default ProjectsPanel