import React from 'react'
import ResetPass from '../components/ResetPass'
import Footer from '../components/Footer'

const ResetPassPage = () => {
  return (
    <div>
      <ResetPass/>
      <Footer />
    </div>
  )
}

export default ResetPassPage