import React from 'react'
  import { 
    Box, 
    Typography, 
    Link
  } from '@mui/material';

const ProjectPanel = ({ value, TabPanel }) => {

    return (
        <TabPanel value={value} index={6} >
          <Box sx={{    
            backgroundColor: '#ededed',
            borderRadius: 8,
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'}}>
            <Typography variant="subtitle1" component="h2" sx={{"font-weight": "bold"}}>
              Manage custom projects from this panel. To get started on a project, {' '}
            <Link href="/booknow" sx={{    
            color: '#2563eb',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            }}}>
              book a free consultation here
            </Link>!
            </Typography>
          </Box>
        </TabPanel>
)
}

export default ProjectPanel