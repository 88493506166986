import React from 'react'
import ResetPassConf from '../components/ResetPassConf'
import Footer from '../components/Footer'

const ResetPassConfPage = () => {
  return (
    <div>
      <ResetPassConf/>
      <Footer />
    </div>
  )
}

export default ResetPassConfPage