import React, { useEffect, useState } from "react";
import { Container, Icon, FormWrap, FormContent, Form, FormH1, FormButton} from "./DeleteAccountElements";
import { getAccountDetails, updateAccountDetails } from "../../services/accountService";


const DeleteAccount = () => {
  const height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const url = window.location.href
  const token=url.slice(url.indexOf("token=") + 6);
  console.log(token)
  localStorage.setItem("token", token);

  const [account, setAccount] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();

    if (account === false) {
      handleDeleteReject()
    }
    else {
      handleDeleteAccept()
    }
  }

  const handleDeleteAccept = () => {
    console.log(localStorage.getItem("token"));
    updateAccountDetails(account, setAccount).then(response => {
      if (response === true) {
        window.parent.location = `/`;
      }
      else {
        setAccount(false);
      }
    })

    window.parent.location = "/"
  }

  const handleDeleteReject = () => {
    window.parent.location = "/contact"
  };

  useEffect(() => {
    getAccountDetails(setAccount)
  }, [])


  return (
    <>
      <Container style={{ minHeight: `${height}px` }}>
        <Icon to="/">Dereva</Icon>
        <FormWrap style={{"minHeight":height -160}}>
          <FormContent>
          <Form onSubmit={handleSubmit}>
            {account === false ? (
             <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <FormH1>Cannot delete account, please resend the deletion email or visit the contact page if you still have open projects.</FormH1>
                <FormButton></FormButton>
            </div>
            ):(
            <div style={{display:"flex", flexDirection:"column"}}>               
              <FormH1>You have successfully deleted your account.</FormH1>
              <FormButton 
              >Homepage</FormButton>
            </div>)}

            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default DeleteAccount