import React from 'react'
import Plans from '../components/Plans'
import Footer from '../components/Footer'

const PlansPage = () => {
  return (
    <div>
      <Plans />
      <Footer />
    </div>
  )
}

export default PlansPage