import React from 'react'
import SignUp from '../components/SignUp'
import Footer from '../components/Footer'

const SignUpPage = (props) => {
  return (
    <div>
      <SignUp setToken={props.setToken}/>
      <Footer minFooterWidth="300px"/>
    </div>
  )
}

export default SignUpPage