import config from '../config';

export async function getTimes(setOptions, date) {
    console.log("hi")
    var response = await fetch(`${config.apiBaseUrl}/get_times`, {
        'method':'POST',
        'headers': {
        'Accept':'*/*',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"date":date})
    }).catch(error => console.log(error))
    if (response.ok === true) {
        response.json().then(data => setOptions(data.times))
    }
}

export async function postAppointment(email, date, time) {
    fetch(`${config.apiBaseUrl}/post_appointment`, {
        'method':'POST',
        'headers': {
        'Accept':'*/*',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"email":email, "date":date, "time":time})
    })
}

export async function deleteAppointment(email) {
    fetch(`${config.apiBaseUrl}/delete_appointment`, {
        'method':'POST',
        'headers': {
        'Accept':'*/*',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"email":email})
    })
}