import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages'
import BookingPage from './pages/Booking'
import AboutusPage from './pages/aboutus'
import InfoPage from './pages/info'
import ThanksPage from './pages/thanks'
import CancelledPage from './pages/cancelled'
import SignInPage from './pages/signin';
import SignUpPage from './pages/signup';
import AccountPage from './pages/account';
import ResetPassPage from './pages/resetpass';
import DocsPage from './pages/docs'
import PlansPage from './pages/plans'
import ResetPassConfPage from './pages/resetpassconf';
import ContactPage from './pages/contact';
import PrivacyPage from './pages/privacy';
import TOSPage from './pages/TOS';
import DeleteAccountPage from './pages/deleteaccount';
import InvestPage from './pages/invest';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/aboutus" element={<AboutusPage/>} />
        <Route path="/account" element={<AccountPage/>} />
        <Route path="/booknow" element={<BookingPage/>} />
        <Route path="/cancelbooking" element={<CancelledPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/deleteaccount" element={<DeleteAccountPage/>} />
        <Route path="/docs" element={<DocsPage/>} />
        <Route path="/howitworks" element={<InfoPage/>} />
        <Route path="/invest" element={<InvestPage/>} />
        <Route path="/plans" element={<PlansPage/>} />
        <Route path="/privacypolicy" element={<PrivacyPage/>} />
        <Route path="/resetpass" element={<ResetPassPage/>} />
        <Route path="/resetpassword" element={<ResetPassConfPage/>} />
        <Route path="/signin" element={<SignInPage/>} />
        <Route path="/signup" element={<SignUpPage/>} />
        <Route path="/thankyou" element={<ThanksPage/>} />
        <Route path="/termsofservice" element={<TOSPage/>} />
      </Routes>
    </Router>
  );
}

export default App;