import config from '../config';
import { updateTokens, getCookie } from './cookieManager';

export async function registerAccount(email, password, name, company) {
    const response = await fetch(`${config.apiBaseUrl}/register`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"email":email,"password":password,"name":name,"company":company})
        });
    if (response.ok === true) {
        response.json().then(json => updateTokens(json))
    }
    return response.ok;

}

export async function authenticateAccount(email, password) {
    try {
        const response = await fetch(`${config.apiBaseUrl}/authenticate`, {
            'method':'POST',
            'headers': {
            'Accept':'application/json',
            'Content-Type':'application/json',
            },
            'body':JSON.stringify({"email":email,"password":password})
        });
        if (response.ok === true) {
            const json = await response.json();
            updateTokens(json);
            return response.ok;
        }
        return response.ok;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function getAccountDetails(setAccount) {
    var token = getCookie('token');
    const response = await fetch(`${config.apiBaseUrl}/accounts`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + token
        }});
    if (response.ok === true) {
        response.json().then(data => {console.log(data);setAccount(data)})
        return response.ok;
    }
    else { 
        var validity = await attemptRefresh()
        return validity
    }
}

export async function attemptRefresh() {
    const response = await fetch(`${config.apiBaseUrl}/refresh`, {
      'method':'POST',
      'headers': {
      'Accept':'application/json',
      'Content-Type':'application/json'},
      'body':JSON.stringify({"refreshToken":getCookie('refreshToken')})
        });
    if (response.ok === true) {
        response.json().then(data => updateTokens(data))
    }
    return response.ok;
  }

  export async function updateAccountDetails(account, setAccount) {
    console.log(account);
    const response = await fetch(`${config.apiBaseUrl}/updateaccount`, {
      'method':'POST',
      'headers': {
      'Accept':'application/json',
      'Content-Type':'application/json',
      'Authorization':'Bearer ' + getCookie('token')},
      'body':JSON.stringify(account)
        });
    if (response.ok !== true) {
        const refreshResponse = await attemptRefresh();
        if (refreshResponse.ok === true) {
            updateAccountDetails(account, setAccount);
        }
    }
    return response.ok;
  }