import React from 'react'
import {FaGithub, FaYelp, FaYoutube, FaLinkedin} from 'react-icons/fa'
import {FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterOutsideLink, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements'

const Footer = ({ minFooterWidth }) => {
  const footerStyle = {
    minWidth: minFooterWidth || 'auto', // Use a specified min-width or fallback to 'auto'
  };

  return (
    <FooterContainer style={footerStyle}>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/howitworks">How It Works</FooterLink>
              <FooterLink to="/aboutus">Who We Are</FooterLink>
              <FooterLink to="/privacypolicy">Privacy Policy</FooterLink>
              <FooterLink to="/termsofservice">Terms of Service</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to="/booknow">Book Appointment</FooterLink>
              <FooterLink to="/invest">Investors</FooterLink>
              <FooterLink to="/account">Account</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              <FooterOutsideLink href="https://www.youtube.com/@DerevaTalks">Guides</FooterOutsideLink>
              <FooterOutsideLink href="https://youtube.com/playlist?list=PL-bza_DPAuwh395qrVaB9ch6j43b2f4Ga">Philosophy</FooterOutsideLink>
              <FooterOutsideLink href="https://www.youtube.com/@DerevaTalks">Testimonials</FooterOutsideLink>
              <FooterOutsideLink href="https://www.youtube.com/@DerevaTalks">Promotional</FooterOutsideLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterOutsideLink href="https://github.com/DerevaSystems">GitHub</FooterOutsideLink>
              <FooterOutsideLink href="https://www.youtube.com/@DerevaTalks">Youtube</FooterOutsideLink>
              <FooterOutsideLink href="https://yelp.com/biz/dereva-systems-yarmouth">Yelp</FooterOutsideLink>
              <FooterOutsideLink href="https://www.linkedin.com/company/dereva-systems/">Linkedin</FooterOutsideLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/'>
              Dereva
            </SocialLogo>
            <WebsiteRights>Dereva Systems Inc. © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="https://github.com/DerevaSystems" target="_blank" aria-label="GitHub">
                <FaGithub />
              </SocialIconLink>
              <SocialIconLink href="https://yelp.com/biz/dereva-systems-yarmouth" target="_blank" aria-label="Yelp">
                <FaYelp />
              </SocialIconLink>            
              <SocialIconLink href="https://www.youtube.com/@DerevaTalks" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink>            
              <SocialIconLink href="https://www.linkedin.com/company/dereva-systems/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer