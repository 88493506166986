import React from 'react'
import { Typography, Box, List, ListItem, Card, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import {
  fontTheme,
  H1Styles,
  H2Styles,
  P1Styles,
  cardStyles
} from '../DocsElements'
import reporterData from '../../../collections/Reporter.postman_collection.json';

const FaxPanel = ({ value, TabPanel}) => {
  const authenticationRequest = reporterData.item[1].response[0].originalRequest;
  const authenticationResponse = reporterData.item[1].response[0];
  const queueRequest = reporterData.item[5].response[0].originalRequest;
  const queueResponse = reporterData.item[5].response[0];
  const statusRequest = reporterData.item[2].response[0].originalRequest;
  const statusResponse = reporterData.item[2].response[0];

  const removeBackslashesAndQuotes = (string) => {
    return string.replace(/\\/g, '').replace(/^"(.*)"$/, '$1').replace(/rn/g, '\n');
  };

  return (
    <TabPanel value={value} index={2} >
      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingLeft: 3 }}>
        <div >
          <Typography theme={fontTheme} style={H1Styles}>
            Fax API
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Introduction
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The fax API offered by Dereva Systems is a comprehensive solution that enables you to autonomously send faxes. Easily integrate this API into your company's workflow to make spending hours sending faxes by hand a thing of the past! Due to the costs of hosting fax services, at the core of this API is eFax's reliable faxing technology. We add to these services by allowing for expanded file types and tracking options.
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Getting started
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            After acquiring a plan from the plans page, you can begin sending faxes. Each plan comes with a set amount of monthly tokens and the maximum amount of attachments that can be sent with each fax. Each fax costs one token to send.  
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            You must first get an access token to send a fax, as is the case with all Dereva services. To do this, send an initial request with your login credentials. The response will also contain a refresh token, which can be used if this token expires.
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Authentication</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationResponse.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Response Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationResponse.body, null, 2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Typography theme={fontTheme} style={H2Styles}>
            Sending Faxes
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The fax request is composed of several parts:
            <List
              sx={{
                listStyleType: 'disc',
                textIndent: '-4px',
                pl: 13,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
              dense
            >
              <ListItem sx={{ fontWeight: '600' }}>
                A fax number
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                The address the fax is being sent to.
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                An identifier
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                A code used to easily keep track of the faxes of one type. A work order number is typically assigned here.
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A list of attachment names and their Base64 value
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                For example: ["testResult.xlsx": "XYZ123"]
              </ListItem>
            </List>
            <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
              After successfully sending a fax request, a 200 response with the fax id will be returned. Use this id or the identifier to check on the status of the fax.
            </Typography>
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Template Email</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{queueRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{queueRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(queueRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{queueResponse.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Response Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(queueResponse.body, null, 2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Typography theme={fontTheme} style={H2Styles}>
            Checking the Status
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            After sending a fax it will take some time to send. Send a status request after about 5 minutes to monitor the status of the fax. There are a myriad ways to check on your faxes. Use only ONE of the following parts to check on the status:
            <List
              sx={{
                listStyleType: 'disc',
                textIndent: '-4px',
                pl: 13,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
              dense
            >
              <ListItem sx={{ fontWeight: '600' }}>
                The fax id
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Returns the fax with that id.
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                An identifier
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
              Returns every fax that used this identifier.
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A fax number
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
              Returns every fax sent to this number
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A date
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
              Returns every fax sent before a specified date (YYYY-MM-DD)
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A status
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
              Returns every fax with a specified status. A status can be "Sent", "Pending", or "failed"
              </ListItem>
            </List>
            <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
              This list is hierarchical, so any request with multiple parts will return whichever part is highest on the list. Failure to include any parts will return all faxes sent by the user. If there are multiple faxes, they are sorted oldest to newest. If successful, all faxes will be returned in a list.
            </Typography>
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Status by Fax ID</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{statusRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{statusRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(statusRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{statusResponse.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Response Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(statusResponse.body, null, 2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </Box>
      </TabPanel>
  )
}

export default FaxPanel