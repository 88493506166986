import React from 'react'
import Invest from '../components/Invest'
import Footer from '../components/Footer'

const InvestPage = () => {
  return (
    <div>
      <Invest />
      <Footer />
    </div>
  )
}

export default InvestPage