import React from 'react'
import Cancelled from '../components/Cancelled'
import Footer from '../components/Footer'

const CancelledPage = () => {
  return (
    <div>
      <Cancelled />
      <Footer />
    </div>
  )
}

export default CancelledPage