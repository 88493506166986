import React from 'react'
import Booking from '../components/Booking'
import Footer from '../components/Footer'

const BookingPage = () => {
  return (
    <div>
      <Booking />
      <Footer minFooterWidth="500px" />
    </div>
  )
}

export default BookingPage