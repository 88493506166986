import React, { useState } from "react";
import { Container, Icon, FormWrap, FormContent, Form, FormH1, FormLabel, FormInput, FormButton, ResetEmailText} from "./ResetPassElements";
import { sendResetPassEmail } from "../../services/emailService";

const ResetPass = () => {
  const height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const [email, setEmail] = useState()
  const [emailSent, setEmailSent] = useState(false)

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    sendResetPassEmail(email);
    setEmailSent(true);
  }

  return (
    <>
      <Container style={{"minHeight":height}}>
        <Icon to="/">Dereva</Icon>
        <FormWrap style={{"minHeight":height -160}}>
          <FormContent>
            <Form onSubmit={handleSubmit} id="form">
              <FormH1>Enter the email associated with your account below!</FormH1>
              <FormLabel htmlFor='for'>Email</FormLabel>
              <FormInput id='email' type='email' onChange={handleEmailChange} required />
              <FormButton id='button'
              >Reset Password</FormButton>
              {emailSent && (
                <ResetEmailText>A reset email has been sent to your inbox.</ResetEmailText>
              )}
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default ResetPass