import React from 'react'
import Thanks from '../components/Thanks'
import Footer from '../components/Footer'

const ThanksPage = () => {
  return (
    <div>
      <Thanks />
      <Footer minFooterWidth="350px"/>
    </div>
  )
}

export default ThanksPage