import React from 'react'
import { Typography, Box, List, ListItem, Card, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import {
  fontTheme,
  H1Styles,
  H2Styles,
  P1Styles,
  cardStyles
} from '../DocsElements'
import reporterData from '../../../collections/Reporter.postman_collection.json';

const SmsPanel = ({ value, TabPanel}) => {
  const authenticationRequest = reporterData.item[1].response[0].originalRequest;
  const authenticationResponse = reporterData.item[1].response[0];
  const SmsRequest = reporterData.item[6].response[0].originalRequest;
  const SmsResponse = reporterData.item[6].response[0];

  const removeBackslashesAndQuotes = (string) => {
    return string.replace(/\\/g, '').replace(/^"(.*)"$/, '$1').replace(/rn/g, '\n');
  };

  return (
    <TabPanel value={value} index={4} >
      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingLeft: 3 }}>
        <div >
          <Typography theme={fontTheme} style={H1Styles}>
            SMS API
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Introduction
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The SMS API offered by Dereva Systems is an elegantly simple solution, allowing users to easily send out text messages. Perfect for reminders, alerts, and promotions! All messages sent out are no reply. In the future we plan on rolling out scheduled messaging to even further automate this process.
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Getting started
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            After acquiring a plan from the plans page, you can begin sending SMS messages. Each plan comes with a set amount of monthly tokens. Each SMS message costs one token to send.
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            You must first get an access token to send an SMS message, as is the case with all Dereva services. To do this, send an initial request with your login credentials. The response will also contain a refresh token, which can be used if this token expires.
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Authentication</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationResponse.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Response Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationResponse.body, null, 2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Typography theme={fontTheme} style={H2Styles}>
            Sending SMS Messages
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The SMS request is composed of two parts:
            <List
              sx={{
                listStyleType: 'disc',
                textIndent: '-4px',
                pl: 13,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
              dense
            >
              <ListItem sx={{ fontWeight: '600' }}>
                A recipient number list
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                The message
              </ListItem>
            </List>
            <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
              A 200 response indicates that the message was successfully sent.
            </Typography>
          </Typography>
          <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">SMS Message</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{SmsRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{SmsRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(SmsRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{SmsResponse.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </Box>
      </TabPanel>
  )
}

export default SmsPanel