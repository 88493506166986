import React from 'react'
import Account from '../components/Account'
import Footer from '../components/Footer'

const AccountPage = () => {
  return (
    <div>
      <Account />
      <Footer minFooterWidth="600px" />
    </div>
  )
}

export default AccountPage