import { attemptRefresh } from "./accountService";
import config from '../config';
import { getCookie } from "./cookieManager";

export async function postTemplate(id, templateFile) {
    const formData = new FormData();
    formData.append('file', templateFile);
    if (id === null ){
        formData.append('id', "");
    }
    else {
        formData.append('id', id);
    }
    
    const response = await fetch(`${config.apiBaseUrl}/postTemplate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + getCookie("token")
        },
        body: formData
    });

    return response.ok;
}


export async function getSimpleTemplates(setTemplates) {
    const response = await fetch(`${config.apiBaseUrl}/getSimpleTemplates`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        }});
    if (response.ok === true) {
        response.json().then(data => console.log(setTemplates(data)))
        return response.ok;
    }
    else { 
        var validity = await attemptRefresh()
        return validity
    }
}

export async function deleteTemplate(id) {
    const response = await fetch(`${config.apiBaseUrl}/removeTemplate`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        },
        'body':JSON.stringify({"id":id})
    });
    if (response.ok === true) {
        return response.ok;
    }
    else { 
        var validity = await attemptRefresh()
        return validity
    }
}

export async function sendResetPassEmail(email) {
    const response = await fetch(`${config.apiBaseUrl}/sendResetPassword`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"to":[email]})
    });
    return response.ok;
}

export async function sendDeleteAccountEmail() {
    const response = await fetch(`${config.apiBaseUrl}/sendDeleteAccount`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        }
    });
    return response.ok;
}

export async function sendAppointmentEmails(email) {
    const response = await fetch(`${config.apiBaseUrl}/getSimpleTemplates`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json',
        'Authorization':'Bearer ' + getCookie("token")
        },
        'body':JSON.stringify({"email":email})
    });
    return response.ok;
}

export async function sendContactEmail(email, subject, message) {
    var finalMessage = "from: " + email + " subject: " + subject + " message: " + message
    const response = await fetch(`${config.apiBaseUrl}/sendSupport`, {
        'method':'POST',
        'headers': {
        'Accept':'application/json',
        'Content-Type':'application/json'
        },
        'body':JSON.stringify({"message":finalMessage})
    });
    return response.ok;
}