import React from 'react'
import DeleteAccount from '../components/DeleteAccount'
import Footer from '../components/Footer'

const DeleteAccountPage = () => {
  return (
    <div>
      <DeleteAccount/>
      <Footer />
    </div>
  )
}

export default DeleteAccountPage