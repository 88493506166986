import { attemptRefresh } from "./accountService";
import config from '../config';
import { getCookie } from "./cookieManager";

export async function getServices() {
  const response = await fetch(`${config.apiBaseUrl}/get_services`, {
      'method':'POST',
      'headers': {
      'Accept':'application/json',
      'Content-Type':'application/json',
      'Authorization':'Bearer ' + getCookie("token")
      }});
  if (response.ok === true) {
      const data = await response.json();
      return(data);
  }
  else { 
      var validity = await attemptRefresh()
      return validity
  }
}
