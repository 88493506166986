import React from 'react'
import { Typography, Box, Link} from '@mui/material';
import {
  fontTheme
} from '../AccountElements'

const SmsPanel = ({ value, TabPanel, data }) => {

  var remainingTokens = 0;

  if (data !== undefined && data['Sms'] !== undefined){
    remainingTokens = data['Sms'].payment.remainingtokens;
  }


    return (
      <TabPanel value={value} index={4} >
        <Box sx={{    
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ededed',
          borderRadius: 8,
          padding: '16px 24px',
          marginBottom: 3}} 
        >

          {remainingTokens > 0 ? (
            <Box sx={{    
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'}} 
            >
            <Typography theme={fontTheme} sx={{    
              fontSize: 25,
              fontWeight: 'bold',
              marginBottom: 2}}
            >
              Remaining Tokens:
            </Typography>
            <Typography theme={fontTheme} sx={{    
              fontSize: 60,
              fontWeight: 'bold',
              color: '#444444',
              marginBottom: 2,}}
            >
              {remainingTokens}
            </Typography>
            </Box>
          ) : (
            <Typography>
              You have no remaining tokens. To acquire more, please{' '}
              <Link href="/plans" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                upgrade your plan
              </Link>
              .
            </Typography>
          )}
        </Box>
        <Box sx={{    
          backgroundColor: '#ededed',
          borderRadius: 8,
          padding: '16px 24px',
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'}}>
            <Typography>
              To find out more about the API,{' '}
              <Link href="/docs" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                see our docs
              </Link>
              . To manage your plan,{' '}
              <Link href="/plans" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                click here
              </Link>
              .
            </Typography>
          </Box>
        </TabPanel>
)
}

export default SmsPanel