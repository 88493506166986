import React, { useEffect } from 'react'
import {
  styles,
  Container,
  FormWrap,
  NavWrap,
  Icon,
  FormH1,
  NavBtn,
  fontTheme,
  FormBtn,
  CancelBtn
} from './PlansElements'
import { 
  Box, 
  Typography, Link,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { getAllPayments, getValidPayments } from '../../services/paymentService';
import { getServices } from '../../services/servicesService';
import { getAccountDetails } from '../../services/accountService';

const Plans = () => {
  const height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const [initialEffectCompleted, setInitialEffectCompleted] = React.useState(false);

  const [account, setAccount] = React.useState(0);
  const [payments, setPayments] = React.useState(0);
  const [validPayments, setValidPayments] = React.useState(0);
  const [services, setServices] = React.useState(0);
  const [selected, setSelected] = React.useState(0);
  const [requiresRequest, setRequiresRequest] = React.useState(true);

  const serviceAttachmentNames = {"Email":"templates", "SMS":"phone numbers", "Fax":"attachments / fax"};
  const requiresRequestList = ["Fax", "SMS"];

  const stripePromise = loadStripe("pk_live_51Mo7pUIt3dNQP7VlqhTZ7rTBFEfFMImRtDgwdm3iXu1eG6skAQ5PWfiOq0PmX3Szif1Z3Gzr5k8majRY6emh1Oc800xVhSXsAF");

  function DataTable({ data }) {
    return (
      <TableContainer style={styles.tableContainer}>
        <Table style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "25%" }}><b>Date</b></TableCell>
              <TableCell style={{ width: "25%" }}><b>Amount</b></TableCell>
              <TableCell style={{ width: "25%" }}><b>Plan</b></TableCell>
              <TableCell style={{ width: "25%" }}><b>Remaining Tokens</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: "25%" }}>{new Date(item.payment.date).toLocaleDateString()}</TableCell>
                <TableCell style={{ width: "25%" }}>${item.payment.price}.00</TableCell>
                <TableCell style={{ width: "25%" }}>{item.service.plan}</TableCell>
                <TableCell style={{ width: "25%" }}>{item.payment.remainingtokens}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function PlanTable({ item }) {
    var currentDate = new Date();

    // Subtract one month from the current date
    currentDate.setMonth(currentDate.getMonth() - 1);

    return (
      <Box sx={{
        flex: '0 0 auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <Box sx={{
      flex: '0 0 auto',
      width: '80%',
      padding: 3,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      '@media (max-width: 550px)': {
        justifyContent: 'center',
        gap: '10px'
      }
      }}>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography theme={fontTheme} sx={{"font-weight": "bold", "marginBottom": 2, fontSize: 18}}>
            Days remaining:
          </Typography>
          <Typography theme={fontTheme} sx={{fontSize: 30}}>
            {Math.floor((new Date(item.payment.date) - currentDate) / (1000 * 60 * 60 * 24))}   
          </Typography>
        </Box>
        <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center'
        }}>
          <Typography theme={fontTheme} sx={{"font-weight": "bold", "marginBottom": 2, fontSize: 18}}>
            Plan:
          </Typography>
          <Typography theme={fontTheme} sx={{fontSize: 30}}>
            {item.service.plan}
          </Typography>
        </Box>
        <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center'
        }}>
          <Typography theme={fontTheme} sx={{"font-weight": "bold", "marginBottom": 2, fontSize: 18}}>
            Tokens Remaining:
          </Typography>
          <Typography theme={fontTheme} sx={{fontSize: 30}}>
            {item.payment.remainingtokens}
          </Typography>
        </Box>
      </Box>
      <CancelBtn onClick={() => handleCancellation(item)}>Cancel Plan*</CancelBtn>
      <Typography theme={fontTheme} sx={{fontSize: 10}}>
            *Cancelled plans still valid until expiration
          </Typography>
      </Box>
    );
  }

  const handleLogout = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("refreshToken", "");
    window.parent.location = `/signin`;
  }

  const handleChange = (event) => {
    setSelected(event.target.value);
    if (requiresRequestList.includes(event.target.value)) {
      setRequiresRequest(false)
    }
    else {
      setRequiresRequest(true)
    }
  }

  const mapServices = (servicesList) => {
    console.log(servicesList);
    const result = {};

    servicesList.forEach((item) => {
      if (!result[item.service]) {
        result[item.service] = [];
      }
      result[item.service].push(item);
    });

    setSelected(Object.keys(result)[0]);
    setServices(result);
  }

  const handleCancellation = async (plan) => {
    try {
      const stripe = await stripePromise;
      await stripe.subscriptions.del(plan.subscriptionid)
      .then(getAllPayments(setPayments));
    } catch (error) {
      // Handle any errors that occurred during the cancellation process
      console.error(error);
      throw error;
    }
  };

  const handlePurchase = async (plan) => {
    try {

      const stripe = await stripePromise;
      // Fetch the plan details (e.g., price, description) from your backend
      // Call Stripe Checkout with the plan details
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          { price: plan.priceid, quantity: 1 },
        ],
        mode: 'subscription',
        successUrl: 'https://derevasystems.com/account', // Redirect URL after successful payment
        cancelUrl: 'https://derevasystems.com/plans', // Redirect URL if the user cancels the payment
        clientReferenceId: account._id.toString(), // Pass the user identifier here
      });
  
      if (error) {
        console.error('Error redirecting to Checkout:', error);
        // Handle the error and sho  w an error message to the user
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      // Handle the error and show an error message to the user
    }
  };

  function renderServices() {
    try {
      return requiresRequest ? (
        services[selected].map((plan) => (
          plan.plan !== "Unlimited" && (
            <Box
              key={plan.idservices}
              sx={{
                flex: '0 0 auto',
                minWidth: 200,
                margin: 2,
                padding: 3,
                backgroundColor: '#fff',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                {plan.plan}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                {plan.tokens} tokens
              </Typography>
              {selected in serviceAttachmentNames && (
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  {plan.attachments} {serviceAttachmentNames[selected]}
                </Typography>
              )}
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h6" sx={{ marginBottom: 1, marginRight: 1 }}>
                  ${plan.price}
                </Typography>
                <Typography variant="subtitle1" sx={{ marginBottom: 1, paddingTop: '2px' }}>
                  / mth
                </Typography>
              </Box>
              <FormBtn
                onClick={() => handlePurchase(plan)}
                disabled={validPayments[selected] && plan._id === validPayments[selected].service._id}
              >
                Select
              </FormBtn>
            </Box>
          )
        ))
      ) : (
        <Box
        sx={{
          flex: '0 0 auto',
          minWidth: 200,
          margin: 2,
          padding: 3,
          backgroundColor: '#fff',
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>Please {' '}
        <Link href="/contact" sx={{    
        color: '#2563eb',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        }}}>
          contact us 
        </Link>
        {' '} to get a plan for this service.</Typography>
        </Box>
      );
    } catch (error) {
      console.log(error);
      return <p>An error occurred while rendering the services.</p>;
    }
  }

  useEffect(() => {
    getServices().then(result => {
      if (result === false) {
        handleLogout()
        return
      }
      mapServices(result);
    }).then(
    getAllPayments(setPayments).then(validity => {
      if (validity === false) {
        handleLogout()
        return
    }})).then(
    getValidPayments(setValidPayments).then(validity => {
      if (validity === false) {
        handleLogout()
        return
    }})).then(
    setInitialEffectCompleted(true)
    )
  
  }, [])
  useEffect(() => {
    getAccountDetails(setAccount).then(validity => {
    if (validity === false) {
      handleLogout()
    }})
  }, [])



  if (!initialEffectCompleted) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <NavWrap>
        <Icon to="/">Dereva</Icon>
        <NavBtn onClick={handleLogout}>
          Log Out
        </NavBtn>
      </NavWrap>
      <FormWrap style={{"minHeight":height -160}}>
        <Box 
          sx={{
            maxWidth: 1000,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
          <Box sx={{
            paddingBottom: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}>

            <FormH1>
              Select Service:
            </FormH1>
            {services !== undefined ? (
            <Select
            sx={{marginLeft: 3, width:'150px', 'font-size':'18px', marginTop:'-5px'}}
            theme={fontTheme}
            value={selected}
            onChange={handleChange}
            >
              {Object.keys(services).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
            </Select>

            ):(<div />)}

          </Box>
          <Box
            sx={{
              width: '90%',
              backgroundColor: '#ededed',
              borderRadius: 8,
              padding: '16px 24px',
              marginBottom: 4,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            >
            <Typography theme={fontTheme} variant="subtitle1" component="h1" sx={{"font-weight": "bold", "marginTop": 2, fontSize: 18}}>
                Service plans
            </Typography>
            <Box
              sx={{
                width: '100%',
                borderRadius: 8,
                padding: '16px 24px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap', 
              }}
            >
            {selected !== undefined ? (
              renderServices()
            ) : (
              <p>No plans available</p>
            )}
            </Box>
            <Typography theme={fontTheme} variant="subtitle1" component="h2" sx={{"font-weight": "bold", fontSize: 18, marginBottom: 4}}>
                Your plan
            </Typography>
            <Box sx={{
            width: '90%',
            backgroundColor:  '#c4c4c4',
            borderRadius: 8,
            padding: '16px 24px',
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'}}>
              {validPayments[selected] !== undefined ?(
                <PlanTable item={validPayments[selected]}/>
              ) : (<Typography>You have no active plan for this service.</Typography>)}
            </Box>
            <Typography theme={fontTheme} variant="subtitle1" component="h2" sx={{"font-weight": "bold", fontSize: 18, marginBottom: 2}}>
                You can read more about our services in the{' '}
              <Link href="/docs" sx={{    
              color: '#2563eb',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }}}>
                docs
              </Link>
              .
            </Typography>
          </Box>
          <Box sx={{
            width: '90%',
            backgroundColor: '#ededed',
            borderRadius: 8,
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'}}>
            <Typography theme={fontTheme}      variant="subtitle1" component="h2" sx={{"font-weight": "bold"}}>
                Your Payments
            </Typography>
            
            {payments[selected] !== undefined ? (
            <DataTable data={payments[selected]}/>
            ):(            
            <Typography>
              You have made no payments for this service.
            </Typography>)}
          </Box>
        </Box> 
      </FormWrap>
    </Container>
  )
}

export default Plans


