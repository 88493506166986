import React from 'react'
import { Typography, Box, List, ListItem, Card, CardContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import {
  fontTheme,
  H1Styles,
  H2Styles,
  P1Styles,
  cardStyles
} from '../DocsElements'
import reporterData from '../../../collections/Reporter.postman_collection.json';

const EmailPanel = ({ value, TabPanel}) => {
  const authenticationRequest = reporterData.item[1].response[0].originalRequest;
  const authenticationResponse = reporterData.item[1].response[0];
  const templateEmailRequest = reporterData.item[4].response[0].originalRequest;
  const templateEmailResponse = reporterData.item[4].response[0];
  const simpleEmailRequest = reporterData.item[4].response[1].originalRequest;
  const simpleEmailResponse = reporterData.item[4].response[1];

  const removeBackslashesAndQuotes = (string) => {
    return string.replace(/\\/g, '').replace(/^"(.*)"$/, '$1').replace(/rn/g, '\n');
  };

  return (
    <TabPanel value={value} index={3} >
      <Box sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 1, paddingLeft: 3 }}>
        <div >
          <Typography theme={fontTheme} style={H1Styles}>
            Email API
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Introduction
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The email API offered by Dereva Systems is a versatile and lightweight solution that enables you to effortlessly send emails in bulk, including unlimited attachments. With this API, you have the flexibility to upload and utilize your own email templates or simply send basic messages. It provides a seamless and efficient way to communicate with your customers, clients, or subscribers.
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            However, it's important to note that one limitation of the current email API is the absence of a built-in mechanism to confirm delivery and track whether the emails reached their intended recipients. While the API excels in simplifying the process of sending emails en masse, it lacks a comprehensive feedback system to verify successful delivery. By default, all emails will be from "no-reply@derevasystems.com". To add a custom address, contact us!
          </Typography>
          <Typography theme={fontTheme} style={H2Styles}>
            Getting started
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            After acquiring a plan from the plans page, you can begin sending emails. Each plan comes with a set amount of monthly tokens and a quantity of email templates that can be saved. Each email costs one token to send. To save an email template, go to the "Email" tab in the Accounts page and click the "Upload an email template" button. If you go over your template limit, you will be unable to add more but they will be saved and accessible. HTML and msg files are currently the only file types supported for upload. Email templates may contain variables that will be defined in the email request, these are defined by "${'{variable}'}". 
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            You must first get an access token to send an email, as is the case with all Dereva services. To do this, send an initial request with your login credentials. The response will also contain a refresh token, which can be used if this token expires.
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Authentication</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{authenticationResponse.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Response Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(authenticationResponse.body, null, 2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Typography theme={fontTheme} style={H2Styles}>
            Sending Emails
          </Typography>
          <Typography theme={fontTheme} style={P1Styles}>
            The email request is composed of several parts:
            <List
              sx={{
                listStyleType: 'disc',
                textIndent: '-4px',
                pl: 13,
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
              dense
            >
              <ListItem sx={{ fontWeight: '600' }}>
                A template ID
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Template ID's are located on the template table. You must be the owner of the template.
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A list of template variables and their respective value
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                See the below request for an example
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Only relevant if using a template
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A list of destination email addresses
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Requires list format even if just one
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                The subject
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Optional, especially if included a template in use
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                The message
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                Optional, especially if included a template in use
              </ListItem>
              <ListItem sx={{ fontWeight: '600' }}>
                A list of attachment names and their Base64 value
              </ListItem>
              <ListItem sx={{ listStyleType: 'circle', marginLeft: '40px' }}>
                For example: ["testResult.xlsx": "XYZ123"]
              </ListItem>
            </List>
            <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
              The only required parameter to send an email is a recipient list with at least one destination. A 200 response indicates that the email was successfully sent.
            </Typography>
          </Typography>
          <Typography theme={fontTheme} sx={{ marginBottom: '20px', textIndent: '40px', fontWeight: '500', fontSize: '18px', textAlign: 'justify' }}>
          </Typography>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Template Email</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{templateEmailRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{templateEmailRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(templateEmailRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{templateEmailResponse.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card style={cardStyles}>
            <CardContent>
              <Typography variant="h6">Simple Email</Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Method:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{simpleEmailRequest.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>URL:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{simpleEmailRequest.url.raw}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Request Body:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {removeBackslashesAndQuotes(JSON.stringify(simpleEmailRequest.body["raw"]), null, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '200px' }}>Status:</TableCell>
                    <TableCell theme={fontTheme} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{simpleEmailResponse.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </Box>
      </TabPanel>
  )
}

export default EmailPanel