import React, { useEffect, useState } from "react";
import { Container, Icon, FormWrap, FormContent, Form, FormH1, FormLabel, FormInput, FormButton} from "./ResetPassConfElements";
import { getAccountDetails, updateAccountDetails } from "../../services/accountService";


const ResetPassConf = () => {
  const height = window.innerHeight|| document.documentElement.clientHeight|| 
  document.body.clientHeight;

  const url = window.location.href
  const token=url.slice(url.indexOf("token=") + 6);
  console.log(token)
  localStorage.setItem("token", token);

  const [account, setAccount] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();

    if (account === false) {
      handlePassReject()
    }
    else {
      handlePassAccept()
    }
  }

  const handlePassAccept = () => {
    updateAccountDetails(account, setAccount).then(response => {
      if (response === true) {
        window.parent.location = "/signin"
      }
      else {
        setAccount(false);
      }
    })
  }

  const handlePassReject = () => {
    window.parent.location = "/resetpass"
  };

  const handlePassChange = (event) => {
    setAccount({ ...account, password: event.target.value });
  };

  const handleConfirmPassChange = (event) => {
    var conf_input = document.getElementById("confpass");
    (event.target.value === account.password ? 
      conf_input.setCustomValidity("") : conf_input.setCustomValidity("Passwords must match."))
  }

  useEffect(() => {
    getAccountDetails(setAccount)
  }, [])


  return (
    <>
      <Container style={{ minHeight: `${height}px` }}>
        <Icon to="/">Dereva</Icon>
        <FormWrap style={{"minHeight":height -160}}>
          <FormContent>
          <Form onSubmit={handleSubmit}>
            {account === false ? (
             <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <FormH1>Invalid token, please resend the reset email.</FormH1>
                <FormButton>Reset password</FormButton>
            </div>
            ):(
            <div style={{display:"flex", flexDirection:"column"}}>               
              <FormH1>Enter a new password below!</FormH1>
              <FormLabel htmlFor='for'>Password</FormLabel> 
              <FormInput id='regPassword' type='password' onInput={handlePassChange} required />
              <FormLabel htmlFor='for'>Confirm password</FormLabel> 
              <FormInput id='confpass' type='password' onInput={handleConfirmPassChange} required />
              <FormButton 
              >Reset password</FormButton>
            </div>)}

            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default ResetPassConf